<template>
  <div class="">
    <OfferForm ref="OfferForm" v-model="formData" @formSubmitted="updateOffer" :operation_type="'update'"/>
  </div>
</template>

<script>
import OfferForm from "@/views/components/seller/OfferForm";
import OfferService from "@/services/OfferService";
import AutoSaveOfferService from "@/services/AutoSaveOfferService";

export default {
  name: "CreateOffer",
  components: {
    OfferForm
  },
  data() {
    return {
      offerService: new OfferService(),
      autoSaveOfferService: new AutoSaveOfferService(),
      child_id: this.$route.params.child_id,
      parent_id: this.$route.params.parent_id,
      formData: {
        publish_status: '',
        project_name: '',
        estimated_cost: '',
        estimated_duration: 60,
        location_id: '',
        lead_source_type_id: '',
        measurement_type_id: '',
        files: [],
        filesThumbnail: [],
        customer: {
          first_name: '',
          last_name: '',
          phone_number: '',
          whatsapp_number: ''
        },
        subtotal_price: 0,
        discount: 0,
        discount_percentage: 1,
        project_date: new Date().toJSON().slice(0, 10).replace(/-/g, '/'),
        payments: [
          {
            name: "Advance Payment, Cash or Current Cheque",
            amount: 0
          },
          {
            name: "Progress Payment Before Delivery Glass and Aluminum",
            amount: 0,
            split: undefined
          },
          {
            name: "Paid Before Handing Over",
            amount: 0,
            split: undefined
          },
          {
            name: "Paid After Handing Over",
            amount: 0
          }
        ],
        itemDetails: {
          offer_tab: 'Elevation', // Elevation , Quantity
          elevations: [],
          quantity: {
            items: [
              {...this.$store.state.app.initItemObj}
            ]
          },
        },
      },
    }
  },
  methods: {
    getOffer() {
      this.$refs.OfferForm.submitting = true;
      this.offerService.show(this.child_id).then(res => {
        // console.log(res)
        this.formData = this.mapping(res);
        this.$refs.OfferForm.isValidNumber = true;
        this.$refs.OfferForm.phoneNumber = res.customer.phone_number;
        if (res.customer.phone_number != res.customer.whatsapp_number) {
          this.$refs.OfferForm.isSameNumber = false;
          this.$refs.OfferForm.whatsappNumber = res.customer.whatsapp_number;
        }
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        const repeatChild = this.$refs.OfferForm.$refs.stepThree.$refs.formRepeat
        // console.log('repeatChild', repeatChild)
        if (Array.isArray(repeatChild)) {
          repeatChild.forEach(child => {
            // console.log('child', child.$refs)
            child.$refs.ItemForm.forEach(ItemForm => {
              ItemForm.fillData()
            })
            if (child.$refs.SubItemForm) {
              child.$refs.SubItemForm.forEach(SubItemForm => {
                // console.log('SubItemForm', SubItemForm)
                SubItemForm.fillData('SubItemForm')
              })
            }
          });
        } else {
          console.log('repeatChild', repeatChild)
          if (repeatChild != undefined)
            repeatChild.$refs.ItemForm?.forEach(ItemForm => {
              ItemForm.fillData()
            })
        }
        // console.log('this.$refs.OfferForm.$refs.stepThree', this.$refs.OfferForm.$refs.stepThree)
        this.$refs.OfferForm.$refs.stepThree.setRefCode()
        // we need to wait until the item form is filled
        setTimeout(() => {
          this.$refs.OfferForm.submitting = false;
        }, 1000)
      });
    },
    mapping(data) {
      const format = {
        publish_status: data.publish_status,
        subtotal_price: data.sub_total,
        discount: data.discount,
        discount_percentage: data.discount_percentage,
        offer_tab: data.offer_tab,
        itemDetails: {
          offer_tab: data.offer_tab, // Elevation , Quantity
          elevations: [],
          quantity: {
            items: [
              {...this.$store.state.app.initItemObj}
            ]
          },
        },
        id: data.id,
        project_name: data.project_name,
        project_date: data.project_date,
        estimated_cost: data.estimated_cost,
        estimated_duration: data.estimated_duration,
        location_id: data.location_id ? [data.location_id] : data.other_location ? [data.other_location] : [],
        lead_source_type_id: data.lead_source_type_id,
        measurement_type_id: data.measurement_type_id,
        files: data.media.map(el => el.id),
        filesThumbnail: data.media.map(el => ({
          ...el,
          original_url: el.original_url
        })),
        customer: {
          first_name: data.customer.first_name,
          last_name: data.customer.last_name,
          phone_number: data.customer.phone_number,
          whatsapp_number: data.customer.whatsapp_number,
        },
        customer_id: data.customer_id,
        payments: data.payments
      }
      if (data.offer_tab === 'Elevation') {
        const tabsArray = []
        data.offer_item.forEach(item => {
          // dont show **Single** tab items in the Elevation tab
          if (item.offer_elevation === 'Single') return
          if (!tabsArray[item.offer_elevation]) {
            tabsArray.push(item.offer_elevation)
          }
        })
        let tabsName = [...new Set(tabsArray)]
        tabsName.forEach((tabName, tabIndex) => {
          format.itemDetails.elevations[tabIndex] = {
            offer_elevation: tabName,
            customTab: true,
            type: tabName,
          }
          format.itemDetails.elevations[tabIndex].items = []
        })
        data.offer_item.forEach(item => {
          // dont show **Single** tab items in the Elevation tab
          if (item.offer_elevation === 'Single') return
          const tabIndex = tabsName.indexOf(item.offer_elevation)
          console.log(item.offer_elevation)
          console.log('tabIndex', tabIndex)
          format.itemDetails.elevations[tabIndex].items.push(this.setItem(item))
          item.sub_items.forEach(subItem => {
            format.itemDetails.elevations[tabIndex]
                .items[format.itemDetails.elevations[tabIndex].items.length - 1]
                .sub_items.push(this.setItem(subItem, true))
          })
        })
        // format.itemDetails.elevations.forEach(elevation => {
        //   if (elevation.items.length === 0) {
        //     elevation.items.push({...this.$store.state.app.initItemObj})
        //   }
        // })
      } else {
        format.itemDetails.quantity.items = []
        data.offer_item.forEach(item => {
          // dont show **Elevation** tab items in the Single tab
          if (item.offer_elevation !== 'Single') return
          format.itemDetails.quantity.items.push(this.setItem(item))
          item.sub_items.forEach(subItem => {
            format.itemDetails.quantity
                .items[format.itemDetails.quantity.items.length - 1]
                .sub_items.push(this.setItem(subItem, true))
          })
        })
      }
      return format;
    },
    setItem(item, isSubItem = false) {
      try {
        const temp = item.additional_option.map(el => el.cost)
        const itemObj = {...this.$store.state.app.initItemObj}
        itemObj.formItemTouched = true
        itemObj.id = item.id
        itemObj.order = item.order
        itemObj.parent_id = item.parent_id
        itemObj.category_id = item.product_category_id
        itemObj.item_id = item.item?.id
        itemObj.description_id = item.description_id
        itemObj.additional_option_ids = item.additional_option?.map(el => el.id)
        itemObj.additional_options_cost = temp.length ? temp.reduce((a, b) => {
          return (+a) + (+b)
        }) : 0
        itemObj.glass_type_id = item.glass_type_id ? [item.glass_type_id] : item.other_glass_type ? [item.other_glass_type] : []
        itemObj.other_glass_type = item.other_glass_type ? item.other_glass_type : undefined
        itemObj.glass_detail_id = item.glass_detail_id ? [item.glass_detail_id] : item.other_glass_detail ? [item.other_glass_detail] : []
        itemObj.other_glass_detail = item.other_glass_detail ? item.other_glass_detail : undefined
        itemObj.width = item.width ? item.width : ''
        itemObj.height = item.height ? item.height : ''
        itemObj.SQM = item.SQM ? item.SQM : ''
        itemObj.item_reference_code = item.item_reference_code
        itemObj.reference_code = item.item?.reference_code
        itemObj.reference_code_id = item.item?.reference_code_id
        itemObj.meter_price = item.meter_price
        itemObj.unit_price = item.unit_price
        itemObj.total_price = item.total_price
        itemObj.quantity = item.quantity
        itemObj.descriptionOptions = []
        itemObj.valueInBetween = undefined
        itemObj.widthError = undefined
        itemObj.heightError = undefined
        itemObj.product_category_note = item.product_category_note
        // hidden fields
        itemObj.sand_blast = item.sand_blast
        itemObj.plan_location = item.plan_location
        if (isSubItem) {
          itemObj.sub_items = undefined
        } else {
          itemObj.sub_items = []
        }
        return itemObj
      } catch (err) {
        console.error(err)
      }
    },
    // setPayment(payments) {
    //   if (!payments.length) {
    //     return this.formData.payments
    //   }
    // },
    publishOffer() {
      this.$refs.OfferForm.submitting = true;
      this.autoSaveOfferService.publishOffer(this.formData.id).then(res => {
        this.$router.push({name: 'all-offers'});
      }).finally(() => {
        this.$refs.OfferForm.submitting = false;
      })
    },
    updateOffer() {
      if (this.$store.getters["appOfferForm/isPublished"] === 'Draft') {
        this.publishOffer()
        return
      }
      this.$refs.OfferForm.submitting = true;
      if (this.$refs.OfferForm.isSameNumber) {
        this.formData.customer.whatsapp_number = this.formData.customer.phone_number
      }
      const formKeysOnly = Object.assign({}, this.formData)
      // remove unused keys before sent request
      delete formKeysOnly.filesThumbnail
      if (this.formData.measurement_type_id === 3) {
        delete formKeysOnly.files
      }
      delete formKeysOnly.itemDetails
      delete formKeysOnly.customer
      delete formKeysOnly.subtotal_price
      delete formKeysOnly.id
      formKeysOnly.payments.forEach(item => {
        if (item.split && !item.split.length) {
          item.split = undefined
        }
      })
      if (typeof formKeysOnly.location_id[0] === 'number') {
        formKeysOnly.location_id = formKeysOnly.location_id[0]
        formKeysOnly.other_location = null
      } else {
        formKeysOnly.location_id = null
        formKeysOnly.other_location = formKeysOnly.location_id[0]
      }
      // formKeysOnly.estimated_cost = +this.formData.estimated_cost.replaceAll(',', '')
      this.offerService.update(this.parent_id, formKeysOnly).then(res => {
        this.$router.push({name: 'all-offers'});
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.$refs.OfferForm.submitting = false;
      });
    },
  },

  mounted() {
    this.getOffer()
  }
}
</script>

<style scoped>
</style>
